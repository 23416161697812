<template>
    <div>
      <article>
        <section>
          <div class="country_dialogs">
            <el-dialog
              :title="`Update Country`"
              :visible.sync="isEditCountryModalVisible"
              :destroy-on-close="true"
              :before-close="handleClose"
              style="text-align: left"
            >
              <div v-loading="submitting">
                <el-form
                  :model="countryForm"
                  :rules="rules"
                  label-position="top"
                  ref="countryForm"
                  class="demo-countryForm2"
                >
                  <div class="row">
                    <div class="col-md-12">
                      <el-form-item
                        label="Country Name"
                        prop="country_name"
                      >
                        <el-input v-model="countryForm.country_name"></el-input>
                      </el-form-item>
                    </div>
                  </div>
  
                  <hr />
                  <el-form-item class="text-center">
                    <el-button
                      style="background-color: #083628; color: white;"
                      @click="editCountry('countryForm')"
                    > Save Country Changes</el-button>
                  </el-form-item>
                </el-form>
  
                <div class="text-center">
                  <div class="text-center">
                    <small>© 2022 Everythingirly, All rights reserved.</small>
                  </div>
                  <div class="text-center">
                    <!--v-model="countryForm.Course" <small>Design by <a href="https://ovalspace.co" style="color: black;"><strong>Ovalspace</strong></a></small> -->
                  </div>
                </div>
              </div>
            </el-dialog>
          </div>
        </section>
      </article>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        loading: false,
        isLoading: false,
  
        isEditCountryModalVisible: false,
        loadingError: false,
        submitting: false,
        countryForm: {
          country_name: "",
        },
  
        rules: {
          country_name: [
            {
              required: true,
              message: "Country Name is required",
              trigger: "blur",
            },
          ],
        },
      };
    },
  
    props: {
      showCountryEditDialog: {
        required: true,
        type: Boolean,
      },
      countryData: {
        required: true,
        type: Object,
      },
    },
  
    watch: {
      showCountryEditDialog() {
        if (this.showCountryEditDialog === true) {
          this.isEditCountryModalVisible = true;
          this.countryForm.country_name =
            this.countryData.country_name;
        } else {
          this.isEditCountryModalVisible = false;
        }
      },
    },
  
    methods: {
      handleClose(done) {
        done();
        this.$emit("closeEditCountryDialog");
      },
  
      async editCountry(formName) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            try {
              this.submitting = true;
              let response = await this.$http.patch(
                `countries/${this.countryData.country_id}`,
                { country_name: this.countryForm.country_name }
              );
              if (
                response.data.success &&
                response.data.message == "COUNTRY_UPDATED_SUCCESSFULLY"
              ) {
                this.$refs[formName].resetFields();
                this.$emit("re-fresh");
                this.$notify({
                  title: "Success",
                  message: "Country updated successfully",
                  type: "success",
                });
                this.$emit("closeEditCountryDialog");
              } else {
                throw "UNEXPECTED_RESPONSE";
              }
            } catch (error) {
              if (error.message == "Network Error") {
                return this.$notify({
                  title: "Connection failed",
                  message: "A network error occurred, please try again.",
                  type: "error",
                });
              }
              this.$notify({
                title: "Unable to update Country",
                message: "An unexpected Error occurred, please try again",
                type: "error",
              });
            } finally {
              this.submitting = false;
            }
          } else {
            return false;
          }
        });
      },
    },
  };
  </script>
  
  <style>
  </style>